define("discourse/plugins/discourse-onboarding-banner/discourse/components/onboarding-banner", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/widgets/post-cooked", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _postCooked, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.visible}}
    <div class="onboarding-banner">
      <div class="onboarding-banner-content">
        {{html-safe this.cooked}}
        <DButton
          class="dismiss-banner"
          @icon="times"
          @action={{this.dismissOnboarding}}
        />
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "4LH5RZh8",
    "block": "[[[41,[30,0,[\"visible\"]],[[[1,\"  \"],[10,0],[14,0,\"onboarding-banner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"onboarding-banner-content\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[[30,0,[\"cooked\"]]],null]],[1,\"\\n      \"],[8,[39,2],[[24,0,\"dismiss-banner\"]],[[\"@icon\",\"@action\"],[\"times\",[30,0,[\"dismissOnboarding\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"html-safe\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-onboarding-banner/discourse/components/onboarding-banner.hbs",
    "isStrictMode": false
  });
  class OnboardingBanner extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "cooked", [_tracking.tracked]))();
    #cooked = (() => (dt7948.i(this, "cooked"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "maxExpired", [_tracking.tracked], function () {
      return false;
    }))();
    #maxExpired = (() => (dt7948.i(this, "maxExpired"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return true;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "dismissed", [_tracking.tracked], function () {
      return false;
    }))();
    #dismissed = (() => (dt7948.i(this, "dismissed"), void 0))();
    constructor() {
      super(...arguments);
      const date = Date.now();
      let storageObject;
      let storedTopicId;
      let localExpired;
      let maxExpired;
      let getLocal = localStorage.getItem("onboarding_topic");
      if (getLocal) {
        storageObject = JSON.parse(getLocal);
        storedTopicId = storageObject.storedTopicId;
        let cached = storageObject.timestamp;
        let firstSeen = storageObject.firstSeen;
        let expiration = 21600 * 1000; // 6 hours
        let maxAge = 604800 * 1000; // 7 days

        if (date - cached > expiration) {
          localExpired = true;
        }
        if (date - firstSeen > maxAge) {
          maxExpired = true;
        }
      }
      if (maxExpired) {
        this.isLoading = false;
        this.maxExpired = true;
        return;
      }
      const topicId = this.siteSettings.discourse_onboarding_banner_topic_id;
      if (!getLocal || localExpired || storedTopicId && topicId !== storedTopicId
      // if no local storage, or if storage is expired, or if a different topic is set in the setting
      ) {
        this.loadContent(topicId, date, storageObject);
      } else {
        if (storageObject) {
          this.cooked = storageObject.cookedContent;
          this.isLoading = false;
        }
      }
    }
    async loadContent(topicId, date, storageObject) {
      try {
        const response = await (0, _ajax.ajax)(`/discourse-onboarding-banner/content.json`);
        // get the topic
        let dataObject = {
          storedTopicId: topicId,
          timestamp: date,
          firstSeen: storageObject ? storageObject.firstSeen : date
        };
        if (response.cooked) {
          const regex = /\{\%sitename\}/gm;
          let firstPost = response.cooked;
          let replacedPost = firstPost.replace(regex, this.siteSettings.title); // replace {%sitename} with site name

          let cachedTopic = new _postCooked.default({
            cooked: replacedPost
          });
          dataObject.cookedContent = cachedTopic.attrs.cooked;
          this.cooked = cachedTopic.attrs.cooked;
        }
        localStorage.setItem("onboarding_topic", JSON.stringify(dataObject));
      } catch {
        this.cooked = null;
      } finally {
        this.isLoading = false;
      }
    }
    get visible() {
      return !this.dismissed && !this.isLoading && !this.maxExpired && this.cooked;
    }
    async dismissOnboarding() {
      let storageObject = {};
      let data = {};
      let getLocal = localStorage.getItem("onboarding_topic");
      if (getLocal) {
        storageObject = JSON.parse(getLocal);
        data = {
          topic_id: storageObject.storedTopicId
        };
      }
      try {
        await (0, _ajax.ajax)("/discourse-onboarding-banner/dismiss.json", {
          type: "PUT",
          data
        });
      } finally {
        this.dismissed = true;
        if (getLocal) {
          storageObject = JSON.parse(getLocal);
          delete storageObject.cookedContent;
          localStorage.setItem("onboarding_topic", JSON.stringify(storageObject));
        }
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "dismissOnboarding", [_object.action]))();
  }
  _exports.default = OnboardingBanner;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OnboardingBanner);
});